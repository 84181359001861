* {
  font-family: 'Dosis', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  background-image: url("../src/assets/images/waves.svg");
}

.menu-items {
  display: flex; 
  flex-wrap: wrap;
  list-style: none;
  margin: 30px auto 0px auto;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 900;
}

header {
  display: flex;
  flex-wrap: wrap;
  top: 0;
}

.nav {
  background-image: linear-gradient(
    to right,
    #132259,
    #132259 50%,
    #000 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  margin: 20px;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.6s ease-in-out;
  font-family: 'Dosis', sans-serif;
}

.nav:before {
  content: '';
  background: #162768;
  border-radius: 3px;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.5s ease-in-out;
}

.nav:hover {
 background-position: 0;
}

.nav:hover::before {
  width:100%;
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 280px auto 0px auto;
  max-height: 50%;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  font-size: 45px;
  color: rgb(221, 79, 79);
}

.header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.sub-text {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 35px;
  color: #bba2ac;

}

.svg-container {
  display: flex;
  justify-content: center;
  width:55px; /* overriding previous values */
  height:55px;
  border-radius: 50%; /* here's the trick */
  background-color: rgb(200, 79, 79);
  margin-top: 50px;
  margin-bottom: 3px;
}

.LI:hover , .spotify:hover , .github:hover {
  opacity: 60%;
  transition: 0.8s;
}

#contact-content {
  margin: 10px 40px 10px 40px; 
}

.github {
  padding: 10px 0px;
  min-width: 45px;
  filter: invert(100%); 
  cursor: pointer;
  transition: 0.8s;
}

.LI {
  padding: 10px 0px;
  width: 32px;
  filter: invert(100%);
  cursor: pointer;
  transition: 0.8s;
}

.spotify {
  filter: drop-shadow(1px 0px 0px black);
  filter: invert(100%);
  cursor: pointer;
  transition: 0.8s;
}

.about-me {
  display: flex;
  flex-direction: column;
  background-color: #162768;
  border-radius: 30px;
  width: auto;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
}


.about-me-title {
  color: rgb(200, 79, 79);
  font-family: 'Dosis', sans-serif;
  font-size: 50px;
}

.about-me-tag {
  margin-top: 200px;
}

.about-me-tag, .about-me-content {
  display: flex;
  justify-content: center;
  color:rgb(184, 182, 182);
  font-family: 'Dosis', sans-serif;
  font-size: 30px;
  padding: 10px 10px 10px 20px;
}

.about-me-content {
  width: auto;
  padding: 15px;
  font-size: 35px;
}

#about-me-tag {
  margin-top: 220px;
  margin-bottom: 30px;
  font-size: 50px;
}

.skills-tag {
  margin-top: 160px;
  margin-bottom: 30px;
  font-size: 50px;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  background-color: #162768;
  border-radius: 30px;
  max-width: 1100px;
  margin: auto;
  margin-bottom: 30px;
  padding: 10px;
}

.skills-tag-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: rgb(200, 79, 79);
  font-family: 'Dosis', sans-serif;
  font-size: 50px;

}

.skills-content {
  padding: 15px;
}

.skills-categories {
  display: flex;
  font-size: 35px;
  font-family: 'Dosis', sans-serif;
  color: rgb(200, 79, 79);
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: baseline;
}

.skills-display {
  display: flex;
  font-size: 35px;
  font-family: 'Dosis', sans-serif;
  color:rgb(184, 182, 182);
  flex: 2;
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: baseline;
}

.skills-tags-lang {
  display: flex;
  justify-content: space-around;
  color:black;
  font-family: 'Dosis', sans-serif;
  font-size: 25px;
  vertical-align: baseline;
  gap: 100px;
}

.skills-tags-tools {
  display: flex;
  justify-content: space-around;
  color:black;
  font-family: 'Dosis', sans-serif;
  font-size: 25px;
  vertical-align: baseline;
  gap: 178px;
}

.skills-tags-frame {
  display: flex;
  justify-content: space-around;
  color:black;
  font-family: 'Dosis', sans-serif;
  font-size: 25px;
  vertical-align: baseline;
  gap: 70px;
}


.icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  margin-bottom: 20px;
  gap: 20px;

}

.html, .git, .react, .nodejs, .mongo {
  filter:invert(49%) sepia(11%) saturate(2809%) hue-rotate(315deg) brightness(84%) contrast(92%);

}

.css {
  width: 50px;
  filter:invert(49%) sepia(11%) saturate(2809%) hue-rotate(315deg) brightness(84%) contrast(92%);
}

.js {
  width: 50px;
  filter:invert(49%) sepia(11%) saturate(2809%) hue-rotate(315deg) brightness(84%) contrast(92%);

}

.experience {
  display: flex;
  flex-direction: column;
  background-color: #162768;
  justify-content: center;
  border-radius: 30px;
  margin: auto;
  margin-bottom: 20px;
  max-width: 1200px;
  padding: 10px;
}

.experience-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color:rgb(184, 182, 182);
  padding-left: 10px;
  padding-right: 10px;
}

.exp-title-main {
  display: flex;
  justify-content: center;
  color: rgb(200, 79, 79);
  font-family: 'Dosis', sans-serif;
  font-size: 50px;
  margin-top: 145px;
  margin-bottom: 0px;
  padding-top: 10px;
}

.exp-title {
  display: flex;
  color: rgb(200, 79, 79);
  font-family: 'Dosis', sans-serif;
  font-size: 28px;
}

.exp-cont {
  display: flex;
  color:rgb(184, 182, 182);
  font-family: 'Dosis', sans-serif;
  font-size: 23px;
}

.exp-title {
  font-style: italic;
}

.projects {
  margin-top: 145px;
  margin-bottom: 30px;
}

.projects-tag {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.project-title {
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  color:rgb(200, 79, 79);
  font-family: 'Dosis', sans-serif;
  font-size: 50px;
}

.projects-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto 70px auto 70px;
}

#allcards {
  display: flex;
  flex-wrap: wrap;
  background-image: black;
  color: rgb(0, 0, 0);
  border-radius: 20px;
  margin: 15px auto 15px auto;
  justify-content: space-between;
  justify-content: space-around;
  padding: 10px;
  min-width: 200px;
  min-height: 200px;                 /*Allows for project cards to have the same size and allow text insde to wrap*/
  max-width: 200px;
  max-height: 200px;
  align-items: center;
}

.project1 {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/images/hero-bg.jpg");     
  background-position: 75% -20%;
  border: 3px solid black; 
  border-radius: 20px;
}

.project3 {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/images/git-it-done.png");
  background-size: 125%;
  border: 3px solid black;
}

.project4 {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/images/taskmasterpro.png");
  background-size: 150%;
  background-position: 48% 60%;
  border: 3px solid black;
}

.project5 {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/images/passgenerator.png");
  background-size: 110%;
  background-position: 48% 120%;
  border: 3px solid black;
  color: black;
}

.project6 {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/images/codequiz.png");
  background-size: 140%;
  background-position: 60% 120%;
  border: 3px solid black;
  color: black;
}

.project7 {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/images/conch.png");
  background-size: 115%;
  background-position: 60% 120%;
  border: 3px solid black;
  color: black;
}

.project8 {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/images/workday.png");
  background-size: 320%;
  background-position: 73% 120%;
  border: 3px solid black;
  color: black;
}

.project9 {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/images/node.png");
  background-size: 145%;
  background-position: 0% 120%;
  border: 3px solid black;
  color: black;
}

.project10 {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/images/travela.jpeg");
  background-size: 155%;
  background-position: 70% 80%;
  border: 3px solid black;
  color: black;
}

.project2 {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/images/budgettracker.png"); 
  background-size: 100%;   
  background-position: 50% ;        
  border: 3px solid black;
  border-radius: 20px;
}

.project11 {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/images/jestRPG.png");
  background-size: 230%;
  background-position: 0% 120%;
  border: 3px solid black;
  color: black;
}

.project12 {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/images/techbloggr.png");
  background-size: 150%;
  background-position: 55% 120%;
  border: 3px solid black;
  color: black;
}

.project13 {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/images/gymquest.png");
  background-size: 170%;
  background-position: 80% 10%;
  border: 3px solid black;
  color: black;
}

.btn {
  background: none;
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}

.btn-footer {
  background: none;
  border: none;
  margin-left: 0px;
  margin-right: 0px;
}

.all-cards-container {
  display: flex;
  justify-content: space-between;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto 70px auto 70px;
}

.cards {
  opacity: 0;
  font-style: italic;
  font-size: 17px;
  font-family: 'Dosis', sans-serif;
}

.card-link {
  text-decoration: none;
}

.cards-title {
  opacity: 0;
  font-family: 'Dosis', sans-serif;
  font-size: 15px;
  color:rgb(221, 79, 79);

}
#allcards:hover > .cards-title {
  opacity: 1;
}

#allcards:hover > .cards {
  opacity: 1;
}

.button-holder {
  opacity: 0;
  display: flex;
  gap: 10px;
}

#allcards:hover > .button-holder {
  opacity: 1;
}

.github3 {
  width: 30px;
  cursor: pointer;
  filter:invert(46%) sepia(12%) saturate(3817%) hue-rotate(318deg) brightness(90%) contrast(91%);
}

.liveurl {
  width: 60px;
  cursor: pointer;
  background: transparent;
  border:1px solid rgb(200, 79, 79);
  border-radius: 4px;
  color:rgb(200, 79, 79);
}

.resume {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Dosis', sans-serif;
  font-size: 30px;
  border-radius: 20px;
  max-width: 220px;
  margin-bottom: 20px;
  margin: 300px auto 10px auto;
  height: 80px;
  color:rgb(221, 79, 79);
}

footer {
  display: flex;
  flex-direction: column;
  margin-top: 160px;
}

.contact-container {
  bottom: 0;
  left:0;
  right: 0;
  display: flex;
  justify-content: center;
}

.resume-container {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.resume-title {
  display: flex;
  flex-direction: column;
  font-family: 'Dosis', sans-serif;
  font-size: 70px;
}

.text-container {
  display: flex;
  flex-direction: column;
}

.text {
  font-size: 50px;
  color:rgb(221, 79, 79);
}

.email, .name, .message {
  color:rgb(221, 79, 79);
  font-size: 30px;
}

.box-container {
  display: flex;
  justify-content: center;
  background-color: #162768;
  width: 550px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
}

.name-line {
  display: flex;
  vertical-align: middle;
  gap: 64px;
  padding-top: 10px;
}

.email-line {
  display: flex;
  vertical-align: middle;
  gap: 61px;
}

.message-line {
  gap: 20px;
}

.message {
  display: flex;
  vertical-align: middle;
}

.label {
  background-color: #162768;
  border-width: 0 0 1px;
  width: 300px;
  outline: none;
  font-size: 20px;
  color:rgb(184, 182, 182);
  border-color: rgb(221, 79, 79);
}

textarea {
  border-width: 0 0 1px;
  outline: none;
  width: 445px;
  height: 130px;
  font-size: 20px;
  background-color: #162768;
  color:rgb(184, 182, 182);
  border-color: rgb(221, 79, 79);
  resize: none;

}

.submit-container {
  display: flex;
  justify-content: center;
  margin: auto;
}

.submit {
  font-size: 30px;
  color: white;
  padding: 5px;
  border-radius: 5px;
  border: none;
  background: none;
  background-color: rgb(221, 79, 79) ;
  cursor: pointer;
}

.error-text {
  color: rgb(226, 32, 32);
  font-size: 25px;
  font-weight: bolder;
}

.download {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75px;
  filter:invert(46%) sepia(12%) saturate(3817%) hue-rotate(318deg) brightness(90%) contrast(91%);

}

.pdf {
  text-decoration: none;
  color: inherit;
}

#hoverbig:hover {
  transform: scale(1.1,1.1);
  transform-origin: center;
  transition: 0.5s;
}

#allcards:hover {
  background-image: none;
  background-color: rgb(184, 182, 182);
  opacity: 1;
}

.contact-container-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}

.contact-container {
  display: flex;
  justify-content: center;
  margin: auto;
}

.LI:hover , .spotify:hover , .github:hover {
  opacity: 60%;
}

.contact-contain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#contact-content {
  margin: 10px 20px 10px 20px; 
}

.github {
  padding: 10px 0px;
  min-width: 45px;
  filter: invert(100%); 
  cursor: pointer;
}

.LI {
  padding: 10px 0px;
  width: 32px;
  filter: invert(100%);
  cursor: pointer;
}

.spotify {
  filter: drop-shadow(1px 0px 0px black);
  filter: invert(100%);
  cursor: pointer;
}

.svg-container {
  display: flex;
  justify-content: center;
  width:55px; /* overriding previous values */
  height:55px;
  border-radius: 50%; /* here's the trick */
  background-color: rgb(200, 79, 79);
  margin-top: 10px;
  margin-bottom: 3px;
}

.btn {
  background: none;
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}

.contact-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.contact-content {
  font-size: 40px;
}

.box-container-contact {
  margin-top: 100px;
}

.email-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 30px;
  color: rgb(184, 182, 182);
}

.email-description {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 40px;
  color: rgb(200, 79, 79);
  text-decoration: none;
}

@media screen and (max-width: 1000px) {

  .menu-items {
    display: flex; 
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin: 30px auto 0px auto;
    font-size: 22px;
    font-weight: 900;
  }
  
  header {
    top: 0;
    gap: 20px;
  }
  
  .nav {
    background-image: linear-gradient(
      to right,
      #132259,
      #132259 50%,
      #000 50%
    );
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 5px 0;
    margin: 20px;
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.6s ease-in-out;
    font-family: 'Dosis', sans-serif;
  }
  
  .nav:before {
    content: '';
    background: #483cb3;
    border-radius: 3px;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.5s ease-in-out;
  }
  
  .nav:hover {
   background-position: 0;
  }
  
  .nav:hover::before {
    width:100%;
  }
}

@media screen and (max-width: 738px) {

  .menu-items {
    display: flex; 
    flex-direction: column;
    text-align: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 0px auto 0px auto;
    font-size: 17px;
    font-weight: 900;
    padding: 0;
    line-height: 1;
  }
  
  header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    top: 0;
  }

  .nav {
    text-decoration: none;
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    padding: 5px 0;
    margin: 0px;
    position: relative;
    font-family: 'Dosis', sans-serif;
  }

  .nav:hover {
    text-decoration: none;
  }

  .about-me-tag {
    margin-top: 100px;
  }

  .about-me-content {
    width: auto;
    padding: 15px;
    font-size: 15px;
  }

  .skills-tag {
    margin-top: 100px;
    margin-bottom: 30px;
    font-size: 50px;
  }

  .skills-tags-lang {
    display: flex;
    flex-direction: column;
    color:black;
    font-family: 'Dosis', sans-serif;
    font-size: 25px;
    gap: 0px;
  }

  
  .skills-tags-tools {
    display: flex;
    flex-direction: column;
    color:black;
    font-family: 'Dosis', sans-serif;
    font-size: 25px;
    gap: 0px;
  }
  
  .skills-tags-frame {
    display: flex;
    flex-direction: column;
    color:black;
    font-family: 'Dosis', sans-serif;
    font-size: 25px;
    gap: 0px;
  }

  .skills-categories {
    font-size: 25px;
    padding-bottom: 0px;
  }

  .skills-display {
    font-size: 20px;
    padding-top: 0px;
  }

  .exp-title-main {
    display: flex;
    justify-content: center;
    color: rgb(200, 79, 79);
    font-family: 'Dosis', sans-serif;
    font-size: 50px;
    margin-top: 80px;
    margin-bottom: 25px;
    padding-top: 10px;
  }

  .exp-title {
    font-size: 20px;
  }

  .exp-cont {
    font-size: 15px;
  }

  .projects {
    margin-top: 100px;
  }

  .resume {
    margin-top: 140px;
  }

  .box-container {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    background-color: transparent;
    margin: auto;
    align-items: center;
  }

  .contact-form {
    display: flex;
    flex-wrap: wrap;
  }

  .label {
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
    border-width: 0 0 1px;
    width: auto;
    outline: none;
    font-size: 20px;
    color:rgb(184, 182, 182);
    border-color: rgb(221, 79, 79);
  }

  .name-line {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    gap: 10px;
  }

  .name {
    display: flex;
    flex-wrap: wrap;
    width: auto;
  }

  .email-line {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    gap: 10px;
  }

  textarea {
    border-width: 0 0 1px;
    outline: none;
    width: 250px;
    height: 130px;
    font-size: 20px;
    background-color: transparent;
    color:rgb(184, 182, 182);
    border-color: rgb(221, 79, 79);
    resize: none;
  
  }


  footer {
    display: flex;
    flex-direction: column;
    margin-top: 110px;
  }
}

@media screen and (max-width: 456px) {

  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 197px auto 0px auto;
    max-height: 50%;
}

  .header-text {
    font-size: 50px;
  }

  .sub-text {
    font-size: 30px;
  }

  #contact-content {
    margin-left: 5px;
    margin-right: 5px;
  }

}

@media screen and (max-width: 318px) {

  .box-container {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    background-color: transparent;
    margin: auto;
    padding-top: 0px;
    align-items: center;
  }

  .contact-form {
    display: flex;
    flex-wrap: wrap;
  }

  .label {
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
    border-width: 0 0 1px;
    width: 245px;
    outline: none;
    font-size: 20px;
    color:rgb(184, 182, 182);
    border-color: rgb(221, 79, 79);
  }

  .name-line {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    gap: 10px;
  }

  .name {
    display: flex;
    flex-wrap: wrap;
    width: auto;
  }

  .email-line {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    gap: 10px;
  }

  .resume-title {
    font-size: 40px;
  }

  footer {
    margin-top: 80px;
  }

  textarea {
    border-width: 0 0 1px;
    outline: none;
    width: 245px;
    height: 130px;
    font-size: 20px;
    background-color: transparent;
    color:rgb(184, 182, 182);
    border-color: rgb(221, 79, 79);
    resize: none;
  
  }

}